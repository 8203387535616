@import "~bootstrap/scss/functions";
@import "variables.scss";

.register-page {
  margin: 50px auto 220px auto;
  width: 95%;
  max-width: 528px;
  padding: 40px 50px;

  @media (max-width: 767px) {
    padding: 40px 15px;
  }

  background: #FFFFFF;
  border: 1px solid #EDEDED;
  box-sizing: border-box;
  border-radius: 4px;


  svg.logo {
    width: 128px;
    height: 40.63px;
    margin: 0 auto 40px;
    display: block;
  }

  p {
    font-style: normal;
    font-size: 18px;
    line-height: 24px;
    /* or 133% */

    text-align: center;

    color: #111111;
  }

}

.register-app-store-wrapper {
  text-align: center;
}