.shipTo-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 15px 24px;
  .padding-left-small > & {
    padding: 15px 0;
  }

  border-top: 1px solid #eee;

  .last-child-border > &:last-child {
    border-bottom: 1px solid #eee;
  }

  &.loading {
    color: #EDEDED,
  }

  svg {
    color: #666;
  }
}