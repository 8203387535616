@import "~bootstrap/scss/functions";
@import "variables.scss";

.add-to-cart-module.bottom-wrapper {
    display: flex;

    .btn {
        padding: 10px 22px!important;
        border: none;
    }

    .quantity-buttons {
        border: 3px solid $neutral-400;
        border-radius: 10px;
        overflow: hidden;
        font-family: "Boston Black";
    }
    .quantity-value {
        border-left: 3px solid $neutral-400;
        border-right: 3px solid $neutral-400;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btn,
    .btn-group {
        border-radius: 8px;
    }

    .btn {
        
    }

    .add-to-cart-button {
        background: $brand-400;
    }

    .btn.decrement,
    .btn.increment {
        font-size: 24px;
        line-height: 33px;
        font-weight: bold;
        color: $brand-800;

        &:hover {
            background-color: $tb-grey;
            // color: $tb-grey;
        }
    }

    .out-of-stock {
        line-height: 24px;
        line-height: 33px;
    }

    .btn-group .count {
        color: #000;
    }
}