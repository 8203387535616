@import "~bootstrap/scss/functions";
@import "./../../variables.scss";

.refinement-buttons {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    padding: 15px 0;

    @media (min-width: 768px) {
        display: none;
    }

    button {
        margin-right: 5px;
        border: 2px solid $brand-500;
        background-color: $neutral-200;
        border-radius: 50rem !important;

        display: inline-block;
        font-weight: 400;
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        cursor: pointer;
        white-space: nowrap;

        height: 48px;
        padding: 0 20px;
        font-size: 1rem;
    }

    button.yes {
        color: white;
        background-color: $brand-500;
    }

    button:hover {
        transition: all 250ms ease-in-out;
        background-color: #666;
        color: white;
    }
}

.modal-wineapp {
    .filter-modal-body {
        @media (max-width: 768px) {
            display: none;
        }
    }
    &.modal-wineapp-enter-active,
    &.modal-wineapp-enter-done {
        .filter-modal-body {
            @media (max-width: 768px) {
                display: block;
            }
        }
    }
    .filter-header {
        color: $brand-700;
        font-weight: bold;
        font-family: "Boston Black";
        font-size: 18px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-transform: uppercase;

        svg {
            width: 16px;
            height: 16px;
        }
    }
}

.toggle-refinement {
    input {
        margin-right: 8px;

        width: 1em;
        height: 1em;
        margin-top: 0.25em;
        vertical-align: top;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        border: 1px solid rgba(0, 0, 0, 0.25);
        -webkit-appearance: none;
        appearance: none;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;

        border-radius: 0.25em;
    }

    input:checked[type=checkbox] {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
        background-color: #EA0055;
        border-color: #EA0055;
    }

    border: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #666666;
}

.sort-by {
    position: relative;
    width: 100px;
    display: flex;
    align-items: center;
    div {
        white-space: nowrap;
    }

    .ais-SortBy {
        select.ais-SortBy-select {
            border: 0;
            outline: 0;
            text-align: end;
            appearance: none;
            padding-right: 8px;
            background-color: transparent;
        }
    }

    svg {
        width: 14px;
        height: 14px;
        transform: rotate(90deg);
    }
}

.pagination {
    display: flex;

    @media (max-width: 767px) {
        justify-content: center;
    }
    @media (min-width: 768px) {
        justify-content: flex-end;
    }

    nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .btn {
        border-radius: 8px;
        border-color: #b1b1b1!important;
        padding: 12px 18px 11px!important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 14px;
        vertical-align: middle;
    }

    .btn:last-child {
        svg {

            transform: rotate(180deg);
        }
    }
}

.number-of-hits-container {
    color: $brand-800;
    font-weight: bold;
    font-family: "Boston Black";

    select {
        border: 2px solid $brand-500;
        border-radius: 15px;
        height: 30px;
        margin: 0 10px;
        line-height: 28px;
        font-size: 0.8rem;
        padding-top: 0.05rem;
        padding-bottom: 0.05rem;
        flex-grow: 1;
    }

    & > div {
        display: flex;
        align-items: center;
    }
}
