@import "~bootstrap/scss/functions";
@import "../../variables.scss";

.rheostat {
    position: relative;
    border: 0;
    margin-top: 1.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 2rem;

    .rheostat-background {
        background-color: $tb-grey;
        border: 0;
        height: 10px!important;
    }

    .rheostat-handle {
        border-radius: 50%;
        background-color: $tb-red-dark;
        width: 26px;
        height: 26px;
        z-index: 2;
        top: -9px!important;

        border: 4px solid #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);


        &::before,
        &::after {
            background-color: transparent;
        }
    }

    .rheostat-progress {
        background-color: $tb-red-dark;
        height: 10px!important;
        top: 0!important;
    }

    .rheostat-value {
        color: #666;
    }

    .rheostat-marker {
        margin-top: 1rem;
        position: absolute;
    }
}

.dot-wrapper {
    margin: 3rem 2rem 1rem;
    display: flex;
    justify-content: space-between;

    .dot {
        background-color: $tb-grey-light;
        border-radius: 50%;
        width: 10px;
        height: 10px;

        &.active {
            background-color: $tb-red-dark;
        }
    }
}