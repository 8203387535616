@import "~bootstrap/scss/functions";
@import "variables.scss";

.cart-item {
    display: flex;
    position: relative;
    
    a {
        text-decoration: none;
        font-weight: normal!important;
    }

    .image-wrapper {
        flex-shrink: 1;
        margin-right: 30px;
        width: 15px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        img {
            width: 80px;
        }
        svg {
            width: 40px;
            height: 40px;
        }
    }

    .quantity-wrapper {
        flex-shrink: 1;
        font-size: 16px;
        line-height: 16px;

        margin-right: 15px;
    }

    .text-wrapper {
        flex-grow: 1;

        .title {
            font-family: "Boston Black";
            color: $brand-800!important;
            a {color: $brand-800!important;}
            
            font-size: 16px;
            line-height: 16px;
            margin-right: 40px;
        }

        .title,
        .text {
            margin-bottom: 15px;
        }
    }
    .price {
        color: $brand-500;
        font-family: "Boston Black";
    }

    .warning {
        color: #EA0055;
    }

    .btn {
        padding: 10px 20px!important;
    }

    .quantity-control {
        border-radius: 10px;
        font-family: "Boston Black";
        &, .btn {
            border: 1px solid $neutral-400;
        }
        .btn {
            color: $brand-700;
            &:first-child, &:last-child {
                color: $brand-800;
                font-size: 18px;
            }
        }
        
    }

    .delete-icon-container {
        position: absolute;
        top: -5px;
        right: 0;
        svg {
            width: 14px;
            height: 14px;
        }
    }
}