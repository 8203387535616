.refinement {
    padding-bottom: 20px;

    em.ais-Highlight-highlighted {
        font-style: normal;
        font-weight: bold;
    }

    .list-group-item {
        border: 0;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    @media (max-width: 767px) {
        .list-group-item {
            border: 0;
            padding-top: 17px;
            padding-bottom: 17px;
        }
    }
    .item {
        color: #666666;
    }

    .form-with-icon {
        position: relative;

        input {
            color: #666666;
        }

        svg {
            color: #666666;
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            width: 16px;
            height: 16px;
        }
    }

    .form-check-input {
        width: 1.5em;
        height: 1.5em;
    }
}