@import "~bootstrap/scss/functions";
@import "variables.scss";

.badge-wineapp {
    color: #fff;
    background-color: $tb-red-dark;
    padding: 5px 12px!important;
    font-size: 14px;
    font-weight: 400!important;
    border-radius: 50rem;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 7px;
    white-space: nowrap;

    transition: all 250ms ease-in-out;

    svg {
        width: 14px;
        height: 14px;
        margin-right: 7px;
    }

    // span {
    //     margin-left: 0;
    //     width: 0;
    //     overflow: hidden;
    // }

    // &:hover span {
    //     margin-left: 7px;
    //     width: initial;
    // }

    span {
        
        // display: none;
    }

    // &:hover span {
    //     display: block;
    // }

    &.vegan,
    &.bio {
        background-color: #1E7200;
    }
    &.bestSeller {
        background-color: $tb-red-dark;
    }
    &.topWine {
        background-color: $tb-red-dark;
    }
    &.soonOutOfStock {
        background-color: #001899;
    }
    &.outOfStock {
        background-color: #666666;
    }
    &.limitedEdition {
        background-color: $tb-red-dark;
    }
    &.discount {
        min-width: 50px;
        background-color: $tb-red-dark;
    }
}