.loading-bottle {
    transform-origin: 50% 50%;
    // animation: 3s ease-in-out 0 changing;


    animation-name:             changing;
    animation-duration:         3s;
    animation-timing-function: ease-in-out;
    animation-direction:        normal;
    animation-iteration-count:  infinite;
    
    @keyframes changing {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(1800deg);
        }
    }
}