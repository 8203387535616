@import "~bootstrap/scss/functions";
@import "../../variables.scss";

.collapsible-title-btn {
  color: $brand-800;
  border-bottom: 1px solid $gray-300;
}

.collapsible-wrapper {
  display: none;
  overflow: hidden;
}

.collapsible-wrapper-enter {
  display: block;
  max-height: 0;
}

.collapsible-wrapper-enter-active {
  display: block;
  transition: max-height 250ms ease-in-out;
  max-height: 500px;
}

.collapsible-wrapper-enter-done {
  display: block;
  max-height: 500px;
}

.collapsible-wrapper-exit {
  display: block;
  max-height: 500px;
}

.collapsible-wrapper-exit-active {
  display: block;
  transition: max-height 250ms ease-in-out;
  max-height: 0;
}

// .collapsible-wrapper-exit-done {}