@import "~bootstrap/scss/functions";
@import "./../../variables.scss";

.navbar-wineapp {
    background-color: white;
    padding: 55px 24px 12px;

    .top,
    .bottom {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @media (max-width: 767px) {
            justify-content: start;
        }
    }
    .bottom {
        margin-top: 1.5rem;

        @media (max-width: 767px) {
            display: none;
        }
    }
    

    .left {
        display: flex;
        align-items: center;
        width: 25%;

        @media (max-width: 767px) {
            flex-grow: 1;
        }
    }

    .middle {
        display: flex;
        align-items: center;
        flex-grow: 1;

        @media (max-width: 767px) {
            margin: 0 1rem;
            flex-grow: 0;
        }
    }

    .right {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media (max-width: 767px) {
            margin: 0 1rem;
        }
    }

    a.nav-link-custom {
        color: $brand-800;
        font-family: "Boston Black";
        text-transform: uppercase;
        text-decoration: none;
        font-weight: bold;
        &.active {
            color: $brand-500;
        }
    }

    .logo {
        width: 100px;
        &.big {
            width: 140px;
        }
        
        @media (min-width: 768px) {
            height: 48px;
            width: 170px;
        }
    }

    .bottom {
        a {
            font-weight: normal;
            margin-right: 2rem;
            white-space: nowrap;

            &.active {
                font-weight: bold;
            }
        }
    }

    .menu-items-container {
        display: flex;
        align-items: center;
        flex-grow: 1;
        justify-content: center;

        @media (max-width: 767px) {
            margin: 0 1rem;
            flex-grow: 0;
        }
    }

    @media (min-width: 768px) {
        padding: 48px 48px 24px;
    }
}