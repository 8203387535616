.refinement-list {
  background-color: #eee;

  button {
    border: 0;
  }
}

.refinement-pill {
  background-color: #EA0055;
  color: white;
  font-size: 12px;

  svg {
    margin-right: 5px;
  }

  a {
    color: white;
    text-decoration: none;
  }

  a:hover {
    color: white;
  }
}