@import "~bootstrap/scss/functions";
@import "./../../variables.scss";

.footer {
    font-size: 16x;
    line-height: 25px;
    font-weight: 400;
    background-color: $brand-800;
    color: #fff;;

    @media (max-width: 767px) {
        .col-md-4 {
            min-height: 200px;
            padding: 25px 25px;

            &:last-child {
                border-bottom: 0;
            }
        }
    }

    @media (min-width: 787px) {
        .col-md-4 {
            padding: 50px 25px;
            max-width: 300px;
            margin-right: 50px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    h4 {
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 20px;
    }

    a {
        color: #fff;
    }

    .smaller {
        font-size: 14px;
    }

    .footer-app-store-links {
        overflow-x: visible;
        display: flex;
        justify-content: flex-start;
        margin-left: -5px;
    }

}

