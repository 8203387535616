@import "~bootstrap/scss/functions";
@import "variables.scss";

@media (max-width: 767px) {
    .App {
        margin-bottom: 84px!important;
    }
}

.bottom-bar {
    background-color: white;
    position: fixed;
    bottom: 0;
    width: 100%;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 35%) !important;

    .col {
        width: 25%;
    }

    &.isLoggedIn .col {
        width: 20%!important;
    }

    a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0;
        text-decoration: none;

        svg {
            // width: 24px;
            height: 24px;
            color: $tb-red-medium;
        }

        span {
            margin-top: 0.5rem;
            padding: 0 0.7rem 0.2rem;
            font-size: 0.7rem;
            line-height: 0.8rem;
            color: black;
        }

        &.active span {
            border-bottom: 1px solid $tb-red-medium;
        }
    }
}