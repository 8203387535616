.offer-page-hero {
    background-size: cover;
    height: 350px;
    margin-left: -1rem;
    margin-right: -1rem;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media (max-width: 767px) {
        height: 250px;
    }

    img {
        width: 100%;
    }

    h1 {
        // position: absolute;
        // bottom: 5%;
        // left: 5%;
        padding: 2rem;
        color: #FFFFFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}

.pagination {
    display: flex;

    @media (max-width: 767px) {
        justify-content: center;
    }
    @media (min-width: 768px) {
        justify-content: flex-end;
    }

    nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .btn {
        border-radius: 8px;
        border-color: #b1b1b1!important;
        padding: 12px 18px 11px!important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 14px;
        vertical-align: middle;
    }

    .btn:last-child {
        svg {

            transform: rotate(180deg);
        }
    }
}