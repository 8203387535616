@import "~bootstrap/scss/functions";
@import "variables.scss";

.login-wrapper {
    margin: 0 auto;
    width: 95%;
    max-width: 528px;
    padding: 40px 101px;

    @media (max-width: 767px) {
        padding: 40px 15px;
    }
    
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    box-sizing: border-box;
    border-radius: 4px;
    

    svg {
        width: 128px;
        height: 40.63px;
        margin: 0 auto 40px;
        display: block;
    }

    p {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        /* or 133% */
        
        text-align: center;
        
        color: #111111;
    }

    label {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        /* identical to box height, or 100% */
        color: #000000;
        display: block;
    }

    .input-wrapper {
        margin-bottom: 35px;
    }

    input,
    button {
        display: block;
        width: 100%;
        border: 1px solid #B1B1B1;
        box-sizing: border-box;
        border-radius: 4px;
        height: 56px;
        padding: 15px;
    }

    .error {
        color: $tb-red-dark;
    }

    button[type=submit] {
        background-color: $tb-red-medium;
        color: white;
    }

    button:disabled {
        background-color: $tb-grey;
        color: white;
    }

    a {
        display: block;
        text-align: center;
        color: black;
    }
}