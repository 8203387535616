.shipTo-switcher {
  & > svg {
    width: 128px;
    height: 40.63px;
    margin: 3rem auto 4rem;
    display: block;
  }

  p {
    margin: 4rem;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #111111;
  }
  .last-child-border > &:last-child {
    border-bottom: 1px solid #eee;
  }

  &.loading {
    color: #EDEDED
  }
}