@import "~bootstrap/scss/functions";
@import "../../variables.scss";

.product {
    width: 100%;
    border: 2px solid $neutral-400;

    a {
        text-decoration: none!important;
    }

    &.loading {
        height: 310px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    padding: 16px 10px 10px;
    border-radius: 16px;

    @media (max-width: 767px) {
        padding: 8px 10px 0;
    }

    @media (min-width: 768px) {
        border: 1px solid #EDEDED;
    }

    &.shadow-hover {
        transition: box-shadow 250ms ease-in-out;
        transition: border 250ms ease-in-out;
    }

    &.shadow-hover:hover {
        border: 1px solid #EDEDED;
        box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
    }

    &.force-border {
        border: 1px solid #EDEDED;
    }

    .badge-wrapper {
        position: relative;
        height: 30px;
        width: 100%;
        margin-bottom: 8px;
        display: flex;
        justify-content: flex-start;
        overflow: hidden;

        // Source: https://www.w3schools.com/howto/howto_css_hide_scrollbars.asp
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .badge-wrapper::-webkit-scrollbar {
        display: none;
    }

    .top-wrapper {
        display: flex;
        width: 100%;

        .image-wrapper {
            display: flex;
            align-items: center;
            margin: 0 20px;

            .preview-image {
                height: 180px;
                width: 42px;
                background-position: center center;
                background-size: cover;

                svg {
                    width: 42px;
                    height: 180px;
                    color: #b1b1b1;
                }
            }
        }

        .text-wrapper {
            flex-grow: 1;
            width: calc(100% - 82px);

            display: flex;
            flex-direction: column;
            justify-content: center;

            .title-wrapper {
                height: 69px;
                margin-bottom: 8px;

                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                .art-nr {
                    font-size: 13px;
                    line-height: 13px;
                    margin-bottom: 8px;
                    color: #666;
                }

                .title {
                    max-height: 48px;
                    overflow: hidden;

                    font-size: 16px;
                    color: #000;
                    font-family: "Boston Black";
                    font-weight: 700;
                    line-height: 16px;
                }
            }

            .text {
                max-height: 48px;
                margin-bottom: 4px;

                color: $brand-800;
                font-size: 14px;
                line-height: 16px;
                white-space: nowrap;

                div {
                    width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }

            .price {
                height: 45px;
                overflow: hidden;
                margin-bottom: 16px;

                color: $brand-500;
                font-size: 16px;
                line-height: 16px;
                white-space: nowrap;

                display: flex;
                flex-direction: column;

                .now {
                    font-weight: 600!important;
                    font-family: "Boston Black";
                }

                .normal {
                    font-size: 14px;
                    text-decoration: line-through;
                    color: #666;
                }

                .delivery-unit {
                    margin-top: auto;
                    text-align: left;
                    font-size: 13px;
                    color: #666;
                    font-style: italic;
                }
            }
        }
    }

    .in-the-cart {
        margin-top: 5px;

        display: flex;
        align-items: center;

        font-size: 13px;
        color: #666;
        font-style: italic;
    }
}