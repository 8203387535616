@import "~bootstrap/scss/functions";
@import "variables.scss";

.cart {
    position: relative;
    cursor: pointer;

    .cart-badge {
        position: absolute;
        top: -5px;
        right: -5px;
        background-color: $tb-red-medium;
        color: white;
        width: 20px;
        height: 20px;
        font-size: 12px;
        text-align: center;
        vertical-align: middle;
        line-height: 20px;
        border-radius: 50%;
    }
}

.total {
    color: $primary;
    font-weight: bold;
}

.mono {
    font-family: 'Fira Mono';
}

.modal-wineapp[name=cart] {
    .head {
        font-family: "Boston Black";
        color: $brand-800;
    }
    .price {
        font-family: "Boston Black";
        color: $brand-800;
        &.total {
            color: $brand-500;
        }
    }
    .also-buy {
        font-family: "Boston Black";
        color: $brand-800;
        text-transform: uppercase;
    }
    .foot {
        button {
            height: 64px;
            font-family: "Boston Black";
            text-transform: uppercase;
        }
    }
    .checkout-items {
        .header {
            font-family: "Boston Black";
            color: $brand-800;
            text-transform: uppercase;
        }
    }
}

.confirmation-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    svg {
        height: 250px;
    }
    p {
        font-family: "Boston Regular";
        text-align: center;
        max-width: 280px;
        line-height: 1.2em;
        margin: 2rem 0 4rem;
        font-size: 18px;
    }
    a {
        padding: 0px 20px;
        background-color: $brand-500;
        font-family: "Boston Black";
        height: 64px;
        line-height: 64px;
        display: block;
        color: white;
    }
}

textarea {
    width: 100%;
    height: 80px;
    border: 1px solid #aaa;
    border-radius: 5px;
    resize: none;
}

.red {
    color: #EA0055;
}

.example-custom-input {
    border: 2px solid $brand-500;
    border-radius: 30px;
    background-color: transparent;
    width: 100%;
    padding: 10px 20px;
    text-align: left;
    display: flex;

    .choose {
        color: $brand-800;
        text-decoration: underline;
    }
}

.react-datepicker__navigation-icon--next::before {
    left: -7px!important;
}
.react-datepicker__navigation-icon--previous::before {
    right: -7px!important;
}

.react-datepicker__navigation {
    overflow: visible!important;
}
.react-datepicker__navigation-icon::before {
    height: 17px!important;
    width: 17px!important;
    border-width: 2px 2px 0 0!important;
}

.react-datepicker__navigation-icon {
    transform: translateY(10px);
}

.react-datepicker__header  {
    background-color: white!important;
    border-bottom: 0!important;
}

.react-datepicker__day {
    background-color: rgba(0, 0, 0, 0.2);
    color: #1E4899!important;
    border-radius: 50%!important;
    font-weight: bold;
}

.react-datepicker__day.react-datepicker__day--selected {
    background-color: #1E4899!important;
    color: white!important;
}

.react-datepicker__day.react-datepicker__day--disabled {
    background-color: transparent;
    color: #E9EAEE!important;
}