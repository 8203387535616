@import "~bootstrap/scss/functions";
@import "variables.scss";

.hsw-wrapper {
    position: relative;

    .hsw {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
        padding: 20px;

        .product-wrapper {
            width: 290px;
            margin-right: 10px;
        }


        // Source: https://www.w3schools.com/howto/howto_css_hide_scrollbars.asp
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .showMore {
        position: absolute;
        top: 0;
        height: 100%;
        width: 70px;

        display: none;
        @media (max-width: 767px) {
            display: none!important;
        }
        justify-content: center;
        align-items: center;

        background: rgb(255,255,255);

        button {
            border: 3px solid $tb-grey;
            background-color: #fff;
            border-radius: 50%;
            width: 60px;
            height: 60px;

            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                color: #EA0055;
            }
        }
    }

    .showMore.left {
        left: -15px;

        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);

        button {
            svg {
                transform: rotate(180deg);
            }
        }
    }

    .showMore.right {
        right: -15px;

        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
    }

    // .scrollBar {
    //     -webkit-user-drag: none;
    //     -khtml-user-drag: none;
    //     -moz-user-drag: none;
    //     -o-user-drag: none;
    //     // user-drag: none;
    // }
}