@import "~bootstrap/scss/functions";
@import "variables.scss";

.product-detail {
    position: relative;

    .hero {
        margin: 0 -0.75rem;

        display: flex;
        flex-direction: column;

        .back-button {
            color: $brand-800;
            font-family: "Boston Black";
        }

        @media (min-width: 768px) {
            border-top: 0;
            margin: 0;
        }

        .top {

            margin: 1rem 1rem 2rem;

            span {
                cursor: pointer;
                display: flex;
                align-items: center;
            }
        }

        .middle {
            display: flex;

            @media (max-width: 767px) {
                max-width: 100%;
                flex-wrap: wrap;
            }

            .left {
                flex-grow: 0;
                position: relative;
                padding: 2rem 3rem;

                background-size: 130px 130px;
                background-repeat: no-repeat;
                background-position: center center;

                @media (max-width: 767px) {
                    padding: 0;
                }

                .preview-image {
                    margin-left: 35px;
                    height: 250px;
                    z-index: 10;
                }

                svg {
                    margin-left: 35px;
                    height: 250px;
                    width: 50px;
                }
            }

            .right {
                flex-grow: 1;

                @media (max-width: 767px) {
                    padding-top: 2rem;
                    padding-left: 24px;
                }

                @media (min-width: 768px) {
                    flex-grow: 0;
                }

                & > * {
                    margin-bottom: 20px;
                }

                h2 {
                    font-size: 20px;
                    font-weight: normal;
                    max-width: 500px;
                    color: $brand-800;
                    font-family: "Boston Black";
                }

                .price {
                    color: #EA0055;
                    font-size: 20px;
                    line-height: 16px;
                    white-space: nowrap;
                    font-weight: 400;
    
                    .now {
                        font-weight: 600!important;
                        font-family: "Boston Black";
    
                        small {
                            margin-left: .5rem;
                            font-size: 14px;
                            color: #666;
                        }
                    }
    
                    .normal {
                        font-size: 14px;
                        text-decoration: line-through;
                        color: #666;
                    }

                    .sale-end {
                        margin-top: .5rem;
                        font-size: 14px;
                        color: #666;
                    }
    
                    .uvp {
                        margin-top: 20px;
                        font-size: 14px;
                        color: #000000;
                    }
                }
            }
        }

        .quote {
            margin: 0 24px;
            font-family: "Boston Black";
            color: $brand-800;

            @media (min-width: 768px) {
                display: none;
            }
        }

        .to-cart {
            margin: 24px;
            display: flex;
            margin-bottom: 16px;

            @media (max-width: 767px) {
                &.desktop {
                    display: none;
                }
            }

            @media (min-width: 768px) {
                &.mobile {
                    display: none;
                }
                margin-left: 0;
            }
        
            .btn.primary,
            .btn-group {
                border-radius: 8px;
            }
        
            .btn-group,
            .btn-outline-secondary {
                background-color: white;
                border-color: $tb-grey;
            }
        
            .btn-group .decrement,
            .btn-group .increment {
                color: $tb-red-medium;
    
                &:hover {
                    background-color: $tb-red-medium;
                    color: $tb-grey;
                }
            }
        
            .btn-group .count {
                color: #000;
            }
        }
    }

    .quote-desktop {
        width: 400px;
        margin: 3rem;
        font-size: 20px;

        @media (max-width: 767px) {
            display: none;
        }

        svg {
            color: $brand-500;
            width: 25px;
        }

        p {
            font-family: "Boston Black";
            color: $brand-800;
        }
    }

    .information {

        h6 {
            margin: 3rem 1rem 2rem;
            width: 80%;
            font-weight: bold;
            font-family: "Boston Black";
            color: $brand-800;
        }

        @media (min-width: 1320px) {
            .flex-if-huge {
                display: flex;
            }
        }

        ul {
            width: 100%;
            list-style: none;
            margin: 0;
            padding: 0 1rem;

            li {
                width: 100%;
                display: flex;
                span {
                    display: inline-block;
                    margin-bottom: 20px;

                    i {
                        color: #666666;
                    }
                }
                .icon {
                    color: $brand-500;
                    width: 12px;
                    margin-right: 12px;

                    .Rotwein {
                        color: #AF1430;
                    }
                    .Weisswein {
                        color: #F8E592;
                    }
                    .Rosé {
                        color: #DFA67E;
                    }
                    .Schaumwein {
                        color: #D3D3D3;
                    }
                    .Süsswein {
                        color: #F1D4BE;
                    }
                }
                .title {
                    min-width: 130px;
                    color: #666666;
                }
                .value {
                    flex-grow: 1;

                    .dots {
                        display: flex;
                        justify-content: space-between;
                        // margin-right: 30px;
                        min-width: 120px;
                        max-width: 150px;
                        .dot {
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background-color: $tb-grey-light;

                            &.active {
                                background-color: $tb-red-medium;
                            }
                        }
                    }
                    .desc {
                        max-width: 150px;
                        font-size: 12px;
                        color: #c4c4c4;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 5px;
                        margin-right: 30px;
                    }
                }
                .value.nowrap {
                    white-space: pre;
                }
            }
        }
    }

    .simmilar {
        margin: 3rem;

        h5 {
            color: #EA0055;
            font-weight: 500;
        }
    }
}