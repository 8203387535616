@import "~bootstrap/scss/functions";
@import "variables.scss";

.about-page {
    .hero {
        background-size: cover;
        height: 350px;
        margin-left: -1rem;
        margin-right: -1rem;
        border-radius: 15px;
    
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @media (max-width: 767px) {
            height: 250px;
        }
    
        img {
            width: 100%;
        }
    
        h1 {
            padding: .5rem 2rem;
            font-family: "Boston Black";
            text-transform: uppercase;
            color: #FFFFFF;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
    }
    .img-sect {
        gap: 8px;
        justify-content: space-between;
        @include notMobile {
            gap: 32px;
        }
    }
    .section {
        h1, h2 {
            text-transform: uppercase;
        }
        h1, h2, h3 {
            color: $brand-800;
            font-family: "Boston Black";
        }
        .exclusiv-gap {
            margin-top: 24px;
            @include notMobile {
                margin-top: 48px;
            }
        }
        h1 {
            font-size: 42px;
        }
        h2 {
            font-size: 24px;
        }
        h3 {
            font-size: 18px;
        }

        .text {
            margin-top: 15px;
        }

        img {
            display: block;
            width: 100%;
            max-width: 100%;
            border-radius: 15px;
            overflow: hidden;
        }
    }
    h2.experts-header {
        text-transform: uppercase;
        font-family: "Boston Black";
        color: $brand-800;
    }
    .sm {
        margin-bottom: 16px;
        img {
            display: block;
            width: 100%;
            max-width: 100%;
            border-radius: 15px;
            overflow: hidden;
        }
        .name {
            margin-top: 15px;
            font-family: "Boston Black";
            font-size: 18px;
        }
        .role {
            font-size: 16px;
            margin-top: 8px;
        }
        .email {
            display: flex;
            gap: 12px;
            align-items: center;
            svg {
                color: $brand-400;
                width: 12px;
                height: 12px;
            }
        }
    }
}