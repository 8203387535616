@import "./components/styles/fonts/fonts.sass";

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here
@import "./variables.scss";

// 3. Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// 4. Include any optional Bootstrap components as you like
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";

@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/utilities/api";

// 5. Add additional custom code here

.wine-type-icon {
    display: inline;
    color: #c4c4c4;
    width: 12px;
    margin-right: 6px;
  
    .Rotwein,
    .Vin.Rouge,
    .Vino.Rosso {
        color: #AF1430;
    }
    .Weisswein,
    .Vin.Blanc,
    .Vino.Bianco {
        color: #F8E592;
    }
    .Rosé,
    //.Rosé,
    .Rosato {
        color: #DFA67E;
    }
    .Schaumwein,
    .Vin.Mousseux,
    .Spumante {
        color: #D3D3D3;
    }
    .Süsswein,
    .Vin.Doux,
    .Vino.Dolce {
        color: #F1D4BE;
    }
    .Orange.Wine,
    .Vin.orange,
    .Vino.arancione {
        color: #ffb25a
    }
  }

/* clears the 'X' from Internet Explorer */
input[type=text]::-ms-clear,
input[type=text]::-ms-reveal {
  display: none;
  width: 0;
  height: 0; 
}

/* clears the 'X' from Chrome */
input[type="text"]::-webkit-search-decoration,
input[type="text"]::-webkit-search-cancel-button,
input[type="text"]::-webkit-search-results-button,
input[type="text"]::-webkit-search-results-decoration {
  display: none; 
}

.cursor-pointer {
    cursor: pointer;
}

.hide-scrollbar {
    // Source: https://www.w3schools.com/howto/howto_css_hide_scrollbars.asp
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        // display: none;
    }
}


