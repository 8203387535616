@import "~bootstrap/scss/functions";
@import "variables.scss";

.sliderOuterWrapper {
    position: relative;
    margin-bottom: 45px;

    .sliderWrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        
        .slide {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;

            transition: left 150ms ease-in-out;
            &.before {
                left: -100%;
            }
            &.active {
                left: 0;
            }
            &.after {
                left: 100%;
            }
            
            background-size: cover;
            border-radius: 16px;
            overflow: hidden;

            display: flex;
            flex-direction: column;
            justify-content: flex-end;


            h1 {
                margin: 0;
                padding: 2rem;
                color: #FFFFFF;
                text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                
                font-size: 2.2rem;
                font-weight: bold;

                small {
                    display: block;
                    font-size: 1.5rem;
                    font-weight: normal;
                }
            }
        }
    }

    .showMoreSliderButtons {
        position: absolute;
        top: 0;
        height: 100%;
        width: 70px;

        @media (max-width: 767px) {
            display: none!important;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        button {
            border: 3px solid $tb-grey;
            background-color: #fff;
            border-radius: 50%;
            width: 60px;
            height: 60px;

            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                color: #EA0055;
            }
        }

        &.left {
            left: -20px;
    
            button {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    
        &.right {
            right: -20px;
        }
    }

    .dotsWrapper {
        position: absolute;

        bottom: -45px;
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        margin-top: 15px;

        .dot {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            margin: 5px;
            background-color: $tb-grey;
            box-shadow: 0 2px 4px rgb(0 0 0 / 35%) !important;

            &.active {
                background-color: $tb-red-medium;
            }
        }
    }
}