@import "~bootstrap/scss/functions";
@import "variables.scss";

.modal-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2000;
    background-color: rgba(0,0,0,0.4);
    transition: background-color 250ms ease-in-out;
}

.modal-overlay-enter {
    display: block;
    background-color: rgba(0,0,0,0);
}

.modal-overlay-enter-active {
    display: block;
    background-color: rgba(0,0,0,0.4);
}

.modal-overlay-enter-done {
    display: block;
}

.modal-overlay-exit {
    display: block;
    background-color: rgba(0,0,0,0.4);
}

.modal-overlay-exit-active {
    display: block;
    background-color: rgba(0,0,0,0);
}

// .modal-overlay-exit-done {}

.modal-wineapp {
    display: none;
}

.modal-wineapp.placeholder-body {
    display: block;

    .wrapper {
        display: block;
    }
    .head {
        display: none;
    }
    .body {
        display: block;

    }
    .foot {
        display: none;
    }
}

.modal-wineapp.modal-wineapp-enter,
.modal-wineapp.modal-wineapp-enter-active,
.modal-wineapp.modal-wineapp-enter-done,
.modal-wineapp.modal-wineapp-exit,
.modal-wineapp.modal-wineapp-exit-active {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    border-left: 1px solid #eee;
    z-index: 2000;

    @media (min-width: 768px) {
        .left-side & {
            top: 0;
            left: 0;
            max-width: 500px;
        }

        .small & {
            max-width: 300px;
        }

        .middle-side & {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 500px;
            max-height: 600px;
        }

        .tiny & {
            max-width: 300px;
            max-height: 400px;
        }

        .right-side & {
            top: 0;
            left: initial;
            right: 0;
            max-width: 500px;
        }
    }

    .tiny & {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 300px;
        max-height: 400px;
    }

    .wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .head {
        display: flex;
        align-items: center;
        padding: 2rem;

        &:empty {
            display: none;
        }
    }
    .body {
        flex-grow: 1;
        overflow-y: scroll;
        z-index: 10;

    }
    .foot {
        &:empty {
            display: none;
        }

        display: grid;
        // justify-content: flex-end;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;

        padding: 1rem;

        border-top: 1px solid #eee;
        background-color: white;

        span {
            grid-column-start: span 2;
        }

        button {
            width: 100%;
            padding: 12px 20px;
            border-radius: 8px;
            color: #666666;
            border: 1px solid #B1B1B1;
            background-color: transparent;
            margin-right: 15px;

            &:disabled {
                opacity: .3;
            }

            &.primary {
                border: 0;
                background-color: #EA0055;
                color: #fff;
            }

            &.full {
                grid-column-start: span 2;
            }
        }
    }
}

.modal-wineapp.modal-wineapp-enter {
    // display: block;
}
.left-side > .modal-wineapp.modal-wineapp-enter {
    transform: translateX(-100%);
}
.right-side > .modal-wineapp.modal-wineapp-enter {
    transform: translateX(100%);
}
.middle-side > .modal-wineapp.modal-wineapp-enter {
    transform: translateY(-100%);
    @media (min-width:768px) {
        transform: translate(-50%, calc(-50% - 100vh));
    }
}
.tiny > .modal-wineapp.modal-wineapp-enter {
    transform: translate(-50%, calc(-50% - 100vh));
}

.modal-wineapp.modal-wineapp-enter-active {
    // display: block;
    transition: transform 250ms ease-in-out;
}
.left-side > .modal-wineapp.modal-wineapp-enter-active,
.right-side > .modal-wineapp.modal-wineapp-enter-active {
    transform: translateX(0%);
}
.middle-side > .modal-wineapp.modal-wineapp-enter-active {
    transform: translateX(0%);
    @media (min-width:768px) {
        transform: translate(-50%, -50%);
    }
}
.tiny > .modal-wineapp.modal-wineapp-enter-active {
    transform: translate(-50%, -50%);
}


// .modal-wineapp.modal-wineapp-enter-done {
//     // display: block;
// }
// .left-side > .modal-wineapp.modal-wineapp-enter-done,
// .right-side > .modal-wineapp.modal-wineapp-enter-done {
//     transform: translateX(0%);
// }
// .middle-side > .modal-wineapp.modal-wineapp-enter-done {
//     transform: translateX(0%);
//     @media (min-width:768px) {
//         transform: translate(-50%, -50%);
//     }
// }


.modal-wineapp.modal-wineapp-exit {
    // display: block;
}
.left-side > .modal-wineapp.modal-wineapp-exit,
.right-side > .modal-wineapp.modal-wineapp-exit {
    transform: translateX(0%);
}
.middle-side > .modal-wineapp.modal-wineapp-exit {
    transform: translateX(0%);
    @media (min-width:768px) {
        transform: translate(-50%, -50%);
    }
}
.tiny > .modal-wineapp.modal-wineapp-exit {
    transform: translate(-50%, -50%);
}


.modal-wineapp.modal-wineapp-exit-active {
    // display: block;
    transition: transform 250ms ease-in-out;
}
.left-side > .modal-wineapp.modal-wineapp-exit-active {
    transform: translateX(-100%);
}
.right-side > .modal-wineapp.modal-wineapp-exit-active {
    transform: translateX(100%);
}
.middle-side > .modal-wineapp.modal-wineapp-exit-active {
    transform: translateY(-100%);
    @media (min-width:768px) {
        transform: translate(-50%, calc(-50% - 100vh));
    }
}
.tiny > .modal-wineapp.modal-wineapp-exit-active {
    transform: translate(-50%, calc(-50% - 100vh));
}

// .modal-wineapp.modal-wineapp-exit-done {}

.modal-wineapp {
    & > .wrapper {
        & > .head,
        & > .foot {
            transition: box-shadow 250ms ease-in-out;
        }
        
        & > .head-shadow {
            box-shadow: 0 0 1rem rgb(0 0 0 / 15%) !important;
        }
        
        & > .foot-shadow {
            box-shadow: 0 0 1rem rgb(0 0 0 / 15%) !important;
        }
    }
}