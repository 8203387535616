@import "~bootstrap/scss/functions";
@import "./../../variables.scss";

.customer-switcher {
  & > svg {
    width: 128px;
    height: 40.63px;
    margin: 3rem auto 4rem;
    display: block;
  }

  p {
    margin: 4rem;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #111111;
  }
  
  .error {
    display: flex;
    justify-content: center;
    margin: auto;
    color: darkred;
    font-weight: 600;
    font-size: 14px;
  }

  .input-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 24px;
    margin: auto;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #111111;
  }

  button {
    display: block;
    width: 70%;
    border: 1px solid #B1B1B1;
    box-sizing: border-box;
    border-radius: 4px;
    height: 56px;
    padding: 15px;
  }

  button[type=submit] {
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    background-color: $brand-500;
    color: white;
  }
}
