@import "~bootstrap/scss/functions";
@import "variables.scss";


.search-icon {
    color: $brand-500;
}

.searchbar {
    position: relative;
    display: flex;
    width: 90%;

    @media (max-width: 767px) {
        display: none;

        &.active {
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            z-index: 2000;
            background-color: white;
        }

        .input-group {
            border: 1px solid grey;
            overflow: hidden;
            border-radius: 50rem !important;
        }

        input {
            border: 0;
        }

        input:focus {
            outline: none;
        }

        .input-group-text {
            background-color: transparent;
            border: 0;

            svg {
                width: 15px;
                height: 15px;
            }

            &.search {
                background-color: $tb-red-medium;
                color: white;
            }
        }
    }

    @media (min-width: 768px) {
        .input-group {
            border: 2px solid $brand-500;
            overflow: hidden;
            border-radius: 2rem;
            height: 48px;
        }

        input {
            border: 0;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }

        input:focus {
            outline: none;
        }

        .input-group-text {
            background-color: transparent;
            border: 0;

            svg {
                width: 15px;
                height: 15px;
            }

            &.close {
                display: none;
            }

            &.search {
                background-color: transparent;
                svg {
                    width: 24px;
                    height: 24px;
                    margin-right: 10px;
                }
            }
        }
    }

    .hitlist {
        background-color: white;
        position: absolute;
        top: 45px;
        left: 0;
        width: 100%;
        border-radius: 8px;
        z-index: 9999;
        overflow: hidden;
        display: none;
        box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;

        @media (max-width: 767px) {
            position: relative;
            top: 0;
            box-shadow: none!important;
            border-radius: 0;
        }
    }

    .hitlist.active {
        display: block;
    }

    .hit {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        overflow: hidden;
        white-space: pre;


        font-size: 16px;
        line-height: 19px;

        color: #666666;
        line-height: 16px;
        vertical-align: middle;
        padding: 13px;

        font-weight: normal!important;
        text-decoration: none;

        &.title {
            background: #EBEBEB;
        }

        a {
            text-decoration: none;
            color: #666666;
        }
    }
}