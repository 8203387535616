@import "~bootstrap/scss/functions";
@import "variables.scss";

.recommendation {
    position: relative;
    background-color: #eee;

    .title {
        display: flex;
        color: $tb-red-medium;
    }

    .change {
        display: flex;
        cursor: pointer;
        font-style: italic!important;
        color: $tb-grey-dark;
    }

    .plz {
        display: flex;
        cursor: pointer;
        font-style: italic!important;
        color: $tb-grey-dark;
    }

    .close-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
        color: white;
        width: 24px;
        height: 24px;
        background-color: grey;
        border-radius: 50%;

        svg {
            width: 12px;
            height: 12px;
        }
    }
}

.modal-wineapp[name=recommendation] {
    .head {
        font-family: "Boston Black";
        text-transform: uppercase;
        color: $brand-800;
        font-size: 16px;
        svg {
            width: 16px;
            height: 16px;
        }
        border-bottom: 1px solid $neutral-400;
    }
    .modal-plz {
        padding: 1rem 2rem;
    
        .title {
            font-size: 1.5rem;
            font-weight: 500;
            line-height: 1.5rem;
            text-align: center;
            font-family: "Boston Black";
            margin: 20px 0;
            text-transform: uppercase;
            font-size: 24px;
        }
        p {
            text-align: center;
        }
        .rounded-pill {
            border: 3px solid $brand-500;
            height: 48px;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    .foot button {
        height: 64px;
        font-family: "Boston Black";
        text-transform: uppercase;
    }
}
.list-suggest {
    width: 100%;

    .list-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eee;
        overflow: hidden;
        white-space: pre;


        font-size: 16px;
        line-height: 19px;

        color: $brand-800;
        text-decoration: none;
        line-height: 16px;
        padding: 13px;

        &.title {
            background: #fff;
            font-size: 14px;
        }

        svg {
            height: 16px;
            width: 16px;
        }
    }
}