@import "~bootstrap/scss/functions";
@import "variables.scss";

.list {
    .item {
        display: flex;
        align-items: center;

        padding: 15px 24px;
        border-bottom: 1px solid #eee;

        &:first-child {
            border-top: 1px solid #eee;
        }

        &:last-child {
            border-bottom: 0;
        }

        a {
            display: flex;
            align-items: center;

            color: #666;
            text-decoration: none;

            width: 100%;

            cursor: pointer;
        }

        &.title {
            color: $tb-red-medium;
            padding-top: 3rem;
            cursor: normal;
        }

        svg.arrow {
            margin-left: auto;
            color: #666;
        }
    }
}