.app-store-links {
  text-align: center;
  img {
    display: inline-block;
    cursor: pointer;
    width: 144px;
    margin: 0 5px;
  }
}

.appstore-modal-body {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  & > * {
    margin: 1rem 2rem;
  }
}

.modal-wineapp {
  color: black;

  a {
    color: black;
  }
}